
import { RestaurantDto } from "@/frontend-client";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component({})
export default class SelectRestaurantStep extends Vue {
  @Prop() restaurantId: string;
  @Prop() restaurantsList: RestaurantDto[];

  restaurantsPage = 1;
  pageSize = 5;

  filterRestaurantName = ""

  updateRestaurantId(newValue: string) {
    this.$emit("updateRestaurantId", newValue);
  }

  next() {
    this.$emit("next");
  }

  back() {
    this.$emit("back");
  }

  get filteredRestaurants() {
    const restaurants = this.filterRestaurantName != null
      ? this.restaurantsList.filter(x => x.name.toLowerCase().includes(this.filterRestaurantName.toLowerCase()))
      : this.restaurantsList

    console.log(restaurants)

    return restaurants;
  }

  get restaurantsFromTheCurrentPage() {
    let rangeStart = (this.restaurantsPage - 1) * this.pageSize;
    let rangeEnd = this.restaurantsPage * this.pageSize;

    if (rangeStart >= this.filteredRestaurants.length) {
      this.restaurantsPage = 1
      rangeStart = (this.restaurantsPage - 1) * this.pageSize;
      rangeEnd = this.restaurantsPage * this.pageSize;
    }

    return this.filteredRestaurants.slice(rangeStart, rangeEnd);
  }
}
