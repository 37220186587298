
import Vue from "vue";
import Component from "vue-class-component";
import {RawLocation} from "vue-router";
import store from "@/store";
import router from "@/router";
import GoogleLogin from "@/lib/GoogleLogin";

@Component({name: "Navigation"})
export default class Navigation extends Vue {

  get username() {
    return this.$store.state.username;
  }

  get masterNavDrawerOpened() {
    return this.$store.state.masterNavDrawerOpened;
  }

  get shouldDisplayNavigation() {
    return this.$store.getters.shouldDisplayToolbar
  }

  logout(): void {
    store.commit("logoutUser");
    store.commit("setPushNotificationEnabled", false);
  }

  goToPath(path: RawLocation) {
    this.$router.push(path).catch(err => {
      /* NOP */
    })
  }

  setMasterNavDrawerOpened(newValue: boolean) {
    this.$store.commit("setMasterNavigationDrawerOpened", newValue);
  }

  get shouldDisplayToolbar() {
    return this.$store.getters.shouldDisplayToolbar
  }
}
