
import Navigation from './views/commons/Navigation.vue'
import Vue from "vue";
import {Component} from "vue-property-decorator";

@Component({
  name: 'app',
  components: {
    Navigation,
  }
})
export default class App extends Vue {

  mounted() {
    const initialMasterDrawerOpened = !["xs", "sm", "md"].includes(this.$vuetify.breakpoint.name)
    this.$store.commit("setMasterNavigationDrawerOpened", initialMasterDrawerOpened)
  }

  shouldDisplayHamburger() {
    return !this.displayBackButton;
  }

  shouldDisplayBackButton() {
    return this.displayBackButton;
  }

  toggleMasterNavDrawerOpened() {
    this.$store.commit("toggleMasterNavigationDrawerOpened");
  }

  goBack() {
    this.$router.back();
  }

  get loading() {
    return this.$store.state.loading;
  }

  get title() {
    return this.$store.getters.titleText;
  }

  get displayBackButton() {
    return this.$store.state.displayBackButton;
  }

  get shouldDisplayToolbar() {
    return this.$store.getters.shouldDisplayToolbar
  }

  get shouldDisplayLoginToolbar() {
    return this.$store.getters.shouldDisplayLoginToolbar
  }
}
