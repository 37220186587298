
import ErrorsComponent from "./ErrorsComponent.vue";
import Vue from "vue";
import Component from "vue-class-component";

@Component({
  components: {
    ErrorsComponent
  }
})
export default class LoadingView extends Vue {
  created() {
    this.$store.commit("clearErrors");
    this.$store.commit("setLoadingTrue");
  }

  get loading() {
    return this.$store.state.loading;
  }
}
