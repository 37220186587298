
import MoneyInput from "@/views/commons/MoneyInput.vue"
import {PaymentDataFieldsValue} from "@/views/orders/components/orderCreateForm/model";
import Vue from "vue";
import {Prop} from "vue-property-decorator";
import Component from "vue-class-component";

@Component({
  components: {MoneyInput}
})
export default class PaymentDataFields extends Vue {
  @Prop() paymentData: PaymentDataFieldsValue

  updatePaymentByCash(newValue: string) {
    this.$emit("input", Object.assign(this.paymentData, {paymentByCash: newValue}))
  }

  updatePaymentByBankTransfer(newValue: string) {
    this.$emit("input", Object.assign(this.paymentData, {paymentByBankTransfer: newValue}))
  }

  updatePaymentByBlik(newValue: string) {
    this.$emit("input", Object.assign(this.paymentData, {paymentByBlik: newValue}))
  }

  updateBankTransferNumber(newValue: string) {
    this.$emit("input", Object.assign(this.paymentData, {bankTransferNumber: newValue}))
  }

  updateBlikPhoneNumber(newValue: string) {
    this.$emit("input", Object.assign(this.paymentData, {blikPhoneNumber: newValue}))
  }
}
