
import { Prop } from "vue-property-decorator";
import Component from "vue-class-component";
import Vue from "vue";
import ErrorsComponent from "@/views/commons/ErrorsComponent.vue";
import { GroupedOrderEntry } from "@/frontend-client";

@Component({
  components: {
    ErrorsComponent
  }
})
export default class UserOrders extends Vue {
  @Prop() groupedEntries!: GroupedOrderEntry[];
}
