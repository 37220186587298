
import Vue from "vue";
import { Prop, Watch } from "vue-property-decorator";
import Component from "vue-class-component";

@Component({})
export default class TimePicker extends Vue {
  @Prop() value!: string;
  @Prop() label!: string;

  time: string | null = null;
  modal2 = false;

  mounted() {
    this.time = this.value;
  }

  inputEvent(event: Event) {
    console.log("event: ", event);
    this.$emit("input", event);
  }

  @Watch("value")
  onPropertyChanged(value: string, oldValue: string) {
    this.time = value;
  }
}
