
import CreateOrderEntry from "@/views/orders/components/orderEntry/CreateOrderEntry.vue";
import Vue from "vue";
import Component from "vue-class-component";
import {Prop} from "vue-property-decorator";

@Component({
  components: {
    CreateOrderEntry
  }
})
export default class NewOrderEntryCard extends Vue {
  @Prop() username!: string;
  @Prop() isEntryCreating!: boolean;

  createEntry() {
    this.$store.commit(`modifyOrderEntry/setDishEntryCreating`,{});
  }
}
