
import Vue from "vue";
import Component from "vue-class-component";

@Component({
  name: "ViewWrapper",
})
export default class ViewWrapper extends Vue {

}
