
import Vue from "vue";
import Component from "vue-class-component";
import ErrorsComponent from "@/views/commons/ErrorsComponent.vue";
import GoogleLoginButton from "@/views/login/GoogleLoginButton.vue";
import {getConfig} from "@/lib/config";

interface UserEntry {
  username: string;
  email: string;
}

@Component({
  components: {
    ErrorsComponent,
    GoogleLoginButton
  }
})
export default class TestLoginView extends Vue {

  usersList: UserEntry[] = [];

  page_number = 1

  mounted() {
    this.getTestUsers()
  }

  get height() {
    return "calc(100vh - 64px)"
  }

  loginAsUser(user: UserEntry) {
    const payload = {
      username: user.username,
      email: user.email
    }
    this.doLogin(payload);
  }

  private getTestUsers() {
    fetch(`${getConfig().currentDomain}/api/auth/testUser/list`, {method: 'GET'})
        .then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json()
        })
        .then((data: UserEntry[]) => {
          this.usersList = data.sort((a, b) => a.username.localeCompare(b.username))
        })
        .catch((error) => {
          console.error('Error:', error);
          this.$router.push({name: 'Login'})
        });
  }

  private doLogin(payload: { email: string; username: string }) {
    fetch(`${getConfig().currentDomain}/api/auth/testUser/login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    })
        .then(response => response.json())
        .then(data => {
          this.$store.commit("loginUser", {username: data.username, token: data.token})
          this.$router.push({"name": "TodayOrders"})
        })
        .catch((error) => {
          console.error('Error:', error);
        });
  }
}
