
import Price from "@/views/commons/PriceElement.vue";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component({
  components: {
    Price
  }
})
export default class PriceSummary extends Vue {
  @Prop() orderDecreaseInPercent!: number;
  @Prop() orderDeliveryCostPerEverybody!: number;
  @Prop() basePriceSum!: number;
  @Prop() orderDeliveryCostPerDish!: number;
  @Prop() allEatingPeopleCount!: number;
  @Prop() totalPrice!: number;
}
