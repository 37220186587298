
import Vue from "vue";
import Component from "vue-class-component";

@Component({})
export default class ErrorsComponent extends Vue {
  get errors() {
    return this.$store.state.errors;
  }
}
