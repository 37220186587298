
import Price from "../../commons/PriceElement.vue";
import MoneyInput from "../../commons/MoneyInput.vue";
import Vue from "vue";
import { Prop } from "vue-property-decorator";
import Component from "vue-class-component";
import { SideDish } from "@/frontend-client";

@Component({
  components: {
    MoneyInput,
    Price
  }
})
export default class SideDishes extends Vue {
  @Prop() initialSideDishes!: SideDish[];

  sideDishes: SideDish[] = [];
  newSideDishName = "";
  newSideDishPrice = 0;
  sideDishFormVisible = false;

  mounted() {
    this.sideDishes = this.initialSideDishes || [];
  }

  addSideDish() {
    const newSideDish: SideDish = {
      name: this.newSideDishName,
      price: this.newSideDishPrice
    };

    this.sideDishes.push(newSideDish);
    this.setSideDishFormVisible(false);
  }

  removeSideDish(sideDishId: string) {
    this.sideDishes = this.sideDishes.filter(sd => sd.id !== sideDishId);
  }

  setSideDishFormVisible(isVisible: boolean) {
    this.sideDishFormVisible = isVisible;
  }

  getSideDishes() {
    return this.sideDishes;
  }
}
