
import Price from "../../../commons/PriceElement.vue";
import {ShowOrderState} from "@/store/modules/ShowOrderModule";
import Component from "vue-class-component";
import {Prop} from "vue-property-decorator";
import Vue from "vue";
import {ParticipantsDishEntry, ParticipantsOrderEntry, ShowOrderDto} from "../../../../frontend-client";
import ErrorHandler from "@/lib/ErrorHandler";
import OrdersApiConnector from "@/lib/api/OrdersApiConnector";
import OrderStateEnum = ShowOrderDto.OrderStateEnum;

@Component({
  components: {
    Price
  }
})
export default class ShowOrderEntry extends Vue {
  @Prop() index!: number;
  @Prop() orderEntry!: ParticipantsOrderEntry;
  @Prop() dishEntry!: ParticipantsDishEntry;
  @Prop() currentUserId!: string;

  ordersConnector = new OrdersApiConnector()

  get order() {
    const showOrderState: ShowOrderState = this.$store.state.showOrder;
    return showOrderState.order;
  }

  canEditOrderEntry() {
    return this.isOrderOwner() || (this.isOrderEntryOwner() && this.order.orderState === OrderStateEnum.CREATED)
  }

  isOrderOwner() {
    return this.order.orderCreatorId === this.currentUserId;
  }

  isOrderEntryOwner() {
    return this.orderEntry.userId === this.currentUserId;
  }

  editDishEntry() {
    this.$store.commit(
        `modifyOrderEntry/setDishEntryEditing`,
        {
          orderEntryId: this.orderEntry.id,
          dishEntryId: this.dishEntry.id
        }
    );
  }

  deleteDishEntry() {
    this.ordersConnector
        .deleteDishEntry(this.orderEntry.id, this.dishEntry.id)
        .then(() => {
          this.$store.commit("setLoadingTrue");
          this.$store.dispatch(`showOrder/fetchOrderDataAction`, this.$store.state.showOrder.order.id);
        })
        .catch(errResponse => ErrorHandler.handleError(errResponse));
  }
}
