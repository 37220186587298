
import BankTransferQRCode from "@/views/orders/components/orderEntry/BankTransferQRCode.vue";
import Component from "vue-class-component";
import {Prop} from "vue-property-decorator";
import {PaymentData} from "@/frontend-client";
import Vue from "vue";

@Component({
  components: {
    BankTransferQRCode
  }
})
export default class PaymentOptionsSummary extends Vue {
  @Prop() paymentData!: PaymentData;

  formatBankAccountNr(unformattedInput: string) {
    if (unformattedInput) {
      const input = unformattedInput.trim();

      if (input.length === 26) {
        let result = input.slice(0, 2) + " ";

        for (let i = 2; i < input.length; i += 4) {
          result += input.slice(i, i + 4) + " ";
        }

        return result;
      }
    }

    return unformattedInput;
  }

  formatBlikPhoneNumber(unformattedInput: string) {
    if (unformattedInput) {
      const input = unformattedInput.trim();

      if (input.length >= 9) {
        return `${input.slice(0, input.length - 6)} ${input.slice(
          input.length - 6,
          input.length - 3
        )} ${input.slice(input.length - 3, input.length)}`;
      }
    }

    return unformattedInput;
  }
}
