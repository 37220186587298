import { render, staticRenderFns } from "./ViewWrapper.vue?vue&type=template&id=49bf57e3&scoped=true&"
import script from "./ViewWrapper.vue?vue&type=script&lang=ts&"
export * from "./ViewWrapper.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "49bf57e3",
  null
  
)

export default component.exports