
import router from "@/router/index";
import ErrorsComponent from "@/views/commons/ErrorsComponent.vue";
import LoadingView from "@/views/commons/LoadingView.vue";
import MoneyInput from "@/views/commons/MoneyInput.vue";
import TimePicker from "@/views/commons/TimePicker.vue";
import ViewWrapper from "@/views/commons/ViewWrapper.vue";
import Vue from "vue";
import Component from "vue-class-component";
import ErrorHandler from "@/lib/ErrorHandler";
import OrdersApiConnector from "@/lib/api/OrdersApiConnector";
import {OrderSaveRequest, RestaurantDto} from "@/frontend-client";
import NoRestaurantsGuard from "@/views/orders/components/orderCreateForm/NoRestaurantsGuard.vue";
import PriceModifiersFields from "@/views/orders/components/orderCreateForm/PriceModifiersFields.vue";
import {PaymentDataFieldsValue, PriceModifierFieldsValue} from "@/views/orders/components/orderCreateForm/model";
import PaymentDataFields from "@/views/orders/components/orderCreateForm/PaymentDataFields.vue";
import SelectRestaurantStep from "@/views/orders/components/orderCreateForm/SelectRestaurantStep.vue"

@Component({
  computed: {
    loading() {
      return this.$store.state.loading;
    }
  },
  components: {
    PaymentDataFields,
    PriceModifiersFields,
    NoRestaurantsGuard,
    ViewWrapper,
    TimePicker,
    MoneyInput,
    LoadingView,
    ErrorsComponent,
    SelectRestaurantStep
  }
})
export default class OrderCreateForm extends Vue {
  restaurantsList: RestaurantDto[] = [];

  stepperState = 1

  // Order
  restaurantId = "";
  orderDate = "";
  timeOfOrder = "";

  priceModifiers: PriceModifierFieldsValue = {
    decreaseInPercent: 0,
    deliveryCostPerEverybody: 0,
    deliveryCostPerDish: 0
  }

  paymentData: PaymentDataFieldsValue = {
    paymentByCash: true,
    paymentByBankTransfer: false,
    bankTransferNumber: "",
    paymentByBlik: false,
    blikPhoneNumber: ""
  }

  connector: OrdersApiConnector = new OrdersApiConnector();

  created() {
    this.$store.commit("setLoadingTrue");
  }

  mounted() {
    this.connector
        .getOrderCreateData()
        .then(response => {
          const restaurantId =
              (response.restaurantsList &&
                  response.restaurantsList[0] &&
                  response.restaurantsList[0].id) ||
              "";

          this.restaurantsList = response.restaurantsList;

          this.restaurantId = restaurantId;
          this.orderDate = response.orderDate;
          this.timeOfOrder = response.timeOfOrder;

          this.priceModifiers = {
            decreaseInPercent: 0,
            deliveryCostPerEverybody: 0,
            deliveryCostPerDish: 0
          }

          this.paymentData = {
            paymentByCash: true,
            paymentByBankTransfer: false,
            bankTransferNumber: "",
            paymentByBlik: false,
            blikPhoneNumber: ""
          }

          if (response.bankTransferNumber) {
            this.paymentData.paymentByBankTransfer = true;
            this.paymentData.bankTransferNumber = response.bankTransferNumber;
          }

          if (response.blikPhoneNumber) {
            this.paymentData.paymentByBlik = true;
            this.paymentData.blikPhoneNumber = response.blikPhoneNumber;
          }

          this.$store.commit("setTitle", "Create new order")
          this.$store.commit("setLoadingFalse");
        })
        .catch(errResponse => ErrorHandler.handleError(errResponse));
  }

  updateRestaurantId(newValue: string) {
    this.restaurantId = newValue;
  }

  updateTimeOfOrder(newValue: string) {
    this.timeOfOrder = newValue;
  }

  submitForm(e: Event) {
    e.preventDefault();

    const order: OrderSaveRequest = {
      restaurantId: this.restaurantId,
      orderDate: this.orderDate,
      timeOfOrder: this.timeOfOrder,
      deliveryData: this.priceModifiers,
      paymentData: this.paymentData
    };

    this.connector.createOrder(order)
        .then(() => router.push({name: "TodayOrders"}))
        .catch(errResponse => ErrorHandler.handleError(errResponse));

    return false;
  }

  cancelEdit() {
    this.$store.commit("modifyOrderEntry/cancelDishEntryModification",{});
  }

  get loading() {
    return this.$store.state.loading;
  }

  next() {
    this.stepperState = this.stepperState + 1
  }

  back() {
    if (this.stepperState > 1) {
      this.stepperState -= 1
    } else {
      this.$router.back()
    }
  }

}
