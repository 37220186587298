
import LoadingView from "@/views/commons/LoadingView.vue";
import {ShowOrderState} from "@/store/modules/ShowOrderModule";
import router from "@/router/index";
import PriceSummary from "@/views/orders/components/PriceSummary.vue";
import ViewWrapper from "@/views/commons/ViewWrapper.vue";
import OrderEntriesCard from "@/views/orders/components/orderEntry/OrderEntriesCard.vue";
import NewOrderEntryCard from "@/views/orders/components/orderEntry/NewOrderEntryCard.vue";
import OrderDataSummary from "@/views/orders/components/OrderDataSummary.vue";
import PaymentOptionsSummary from "@/views/orders/components/PaymentOptionsSummary.vue";
import OrderLockedWarning from "@/views/orders/components/OrderLockedWarning.vue";
import Component from "vue-class-component";
import Vue from "vue";
import {ParticipantsOrderEntry, ShowOrderDto} from "../../frontend-client";
import ErrorHandler from "@/lib/ErrorHandler";
import OrdersApiConnector from "@/lib/api/OrdersApiConnector";
import OrderStateEnum = ShowOrderDto.OrderStateEnum;
import store from "@/store";

import { NativeEventSource, EventSourcePolyfill } from 'event-source-polyfill';

@Component({
  components: {
    OrderLockedWarning,
    PaymentOptionsSummary,
    OrderDataSummary,
    NewOrderEntryCard,
    OrderEntriesCard,
    ViewWrapper,
    PriceSummary,
    LoadingView
  }
})
export default class ShowOrder extends Vue {
  orderId = "";

  ordersConnector = new OrdersApiConnector()

  es: any | null = null

  mounted() {
    this.orderId = this.$route.params.id;
    this.fetchOrder();
    this.handleOrderChangeSSE();
  }

  // Lifecycle method, so unlike IntelliJ shows it, it is used
  /* exported beforeDestroy */
  beforeDestroy() {
    if (this.es != null) {
      this.es.close();
    }
  }

  fetchOrder() {
    this.$store.commit("setLoadingTrue");
    this.$store.dispatch(`showOrder/fetchOrderDataAction`, this.orderId);
  }

  isOrdering(): boolean {
    return this.order.orderState === OrderStateEnum.ORDERING;
  }

  isOrderOwner(): boolean {
    return this.order.orderCreatorId === this.currentUserId;
  }

  placeOrder() {
    router.push({name: "OrderView", params: {id: this.orderId}});
  }

  setAsDelivered() {
    this.$store.commit("setLoadingTrue");
    this.ordersConnector
        .setOrderAsDelivered(this.orderId)
        .then(() => {
          this.$store.dispatch(`showOrder/fetchOrderDataAction`, this.$store.state.showOrder.order.id);
        })
        .catch(errResponse => {
          this.$store.commit("setLoadingFalse");
          ErrorHandler.handleError(errResponse)
        });
  }

  goToEditOrder() {
    router.push({name: "OrderEditForm", params: {id: this.orderId}});
  }

  canShowPlaceOrderButton(): boolean {
    return this.isOrderOwner() && [OrderStateEnum.CREATED, OrderStateEnum.ORDERING].includes(this.orderState);
  }

  canShowMarkAsDeliveredButton(): boolean {
    return this.isOrderOwner() && this.orderState === OrderStateEnum.ORDERED;
  }

  isPlaceOrderButtonDisabled(): boolean {
    return this.orderEntries.length === 0;
  }

  allEatingPeopleCount(): number {
    return this.orderEntries.flatMap(e => e.dishEntries).length;
  }

  private handleOrderChangeSSE() {
    this.es = new EventSourcePolyfill('/api/orders/sse', {headers: {Authorization: "Bearer " + this.$store.state.token}});

    // const controlListener = function (event: any) {
    //   const type = event.type;
    //   console.log(type + ": " + (type === "message" ? event.data : es.url))
    // };

    const eventListener = (event: any) => {
      const data = JSON.parse(event.data)
      if (data.orderId === this.orderId) {
        this.$store.dispatch(`showOrder/fetchOrderDataAction`, this.orderId);
      }
    };

    // es.addEventListener("open", controlListener);
    this.es.addEventListener("message", eventListener);
    // es.addEventListener("error", controlListener);
  }

  get numberOfCurrentUserEntries(): number {
    return this.orderEntries.filter(e => e.userId === this.currentUserId)
        .length;
  }

  get orderState(): OrderStateEnum {
    const showOrder: ShowOrderState = this.$store.state.showOrder;
    return showOrder.order.orderState;
  }

  get order(): ShowOrderDto {
    const showOrder: ShowOrderState = this.$store.state.showOrder;
    return showOrder.order;
  }

  get orderEntries(): ParticipantsOrderEntry[] {
    const showOrder: ShowOrderState = this.$store.state.showOrder;
    return showOrder.orderEntries;
  }

  get yourOrderEntries(): ParticipantsOrderEntry[] {
    const showOrder: ShowOrderState = this.$store.state.showOrder;
    return showOrder.orderEntries.filter(e => e.userId === this.currentUserId);
  }

  get otherUsersOrderEntries(): ParticipantsOrderEntry[] {
    const showOrder: ShowOrderState = this.$store.state.showOrder;
    return showOrder.orderEntries.filter(e => e.userId !== this.currentUserId);
  }

  get currentUserId(): string {
    const showOrder: ShowOrderState = this.$store.state.showOrder;
    return showOrder.currentUserId;
  }

  get totalOrderPrice(): number {
    const showOrder: ShowOrderState = this.$store.state.showOrder;
    return showOrder.totalOrderPrice;
  }

  get baseOrderPrice(): number {
    const showOrder: ShowOrderState = this.$store.state.showOrder;
    return showOrder.baseOrderPrice;
  }

  get isEntryCreating(): boolean {
    return this.$store.state.modifyOrderEntry.isEntryCreating;
  }

  get username(): string {
    return this.$store.state.username;
  }

  shouldDisplayNewOrderEntryCard(): boolean {
    return this.order.orderState == OrderStateEnum.CREATED && this.numberOfCurrentUserEntries === 0
  }
}
