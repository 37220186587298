/// <reference path="./custom.d.ts" />
// tslint:disable
/**
 * Restaurant Import API
 * To use the API please use the Import API Key specified on \"Restaurants\" page
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This file is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the file manually.
 */

import * as url from "url";
import * as isomorphicFetch from "isomorphic-fetch";
import { Configuration } from "./configuration";

const BASE_PATH = "http://localhost:8080/".replace(/\/+$/, "");

/**
 *
 * @export
 */
export const COLLECTION_FORMATS = {
    csv: ",",
    ssv: " ",
    tsv: "\t",
    pipes: "|",
};

/**
 *
 * @export
 * @interface FetchAPI
 */
export interface FetchAPI {
    (url: string, init?: any): Promise<Response>;
}

/**
 *
 * @export
 * @interface FetchArgs
 */
export interface FetchArgs {
    url: string;
    options: any;
}

/**
 *
 * @export
 * @class BaseAPI
 */
export class BaseAPI {
    protected configuration: Configuration;

    constructor(configuration?: Configuration, protected basePath: string = BASE_PATH, protected fetch: FetchAPI = isomorphicFetch) {
        if (configuration) {
            this.configuration = configuration;
            this.basePath = configuration.basePath || this.basePath;
        }
    }
};

/**
 *
 * @export
 * @class RequiredError
 * @extends {Error}
 */
export class RequiredError extends Error {
    name: "RequiredError"
    constructor(public field: string, msg?: string) {
        super(msg);
    }
}

/**
 * 
 * @export
 * @interface AllOrdersOrderDto
 */
export interface AllOrdersOrderDto {
    /**
     * 
     * @type {string}
     * @memberof AllOrdersOrderDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof AllOrdersOrderDto
     */
    restaurantName: string;
    /**
     * 
     * @type {string}
     * @memberof AllOrdersOrderDto
     */
    orderCreatorUsername: string;
    /**
     * 
     * @type {string}
     * @memberof AllOrdersOrderDto
     */
    orderDate: string;
    /**
     * 
     * @type {string}
     * @memberof AllOrdersOrderDto
     */
    orderState: AllOrdersOrderDto.OrderStateEnum;
}

/**
 * @export
 * @namespace AllOrdersOrderDto
 */
export namespace AllOrdersOrderDto {
    /**
     * @export
     * @enum {string}
     */
    export enum OrderStateEnum {
        CREATED = <any> 'CREATED',
        ORDERING = <any> 'ORDERING',
        ORDERED = <any> 'ORDERED',
        DELIVERED = <any> 'DELIVERED',
        REJECTED = <any> 'REJECTED'
    }
}
/**
 * 
 * @export
 * @interface AllOrdersResponse
 */
export interface AllOrdersResponse {
    /**
     * 
     * @type {Array<AllOrdersOrderDto>}
     * @memberof AllOrdersResponse
     */
    allOrdersList: Array<AllOrdersOrderDto>;
}
/**
 * 
 * @export
 * @interface AuthUserInfo
 */
export interface AuthUserInfo {
    /**
     * 
     * @type {string}
     * @memberof AuthUserInfo
     */
    token: string;
    /**
     * 
     * @type {string}
     * @memberof AuthUserInfo
     */
    userId: string;
    /**
     * 
     * @type {string}
     * @memberof AuthUserInfo
     */
    username: string;
}
/**
 * 
 * @export
 * @interface CreateDishResponse
 */
export interface CreateDishResponse {
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateDishResponse
     */
    categories: Array<string>;
}
/**
 * 
 * @export
 * @interface CreateOrderInitialData
 */
export interface CreateOrderInitialData {
    /**
     * 
     * @type {Array<RestaurantDto>}
     * @memberof CreateOrderInitialData
     */
    restaurantsList: Array<RestaurantDto>;
    /**
     * 
     * @type {string}
     * @memberof CreateOrderInitialData
     */
    orderDate: string;
    /**
     * 
     * @type {string}
     * @memberof CreateOrderInitialData
     */
    timeOfOrder: string;
    /**
     * 
     * @type {string}
     * @memberof CreateOrderInitialData
     */
    bankTransferNumber: string;
    /**
     * 
     * @type {string}
     * @memberof CreateOrderInitialData
     */
    blikPhoneNumber: string;
}
/**
 * 
 * @export
 * @interface DeliveryData
 */
export interface DeliveryData {
    /**
     * 
     * @type {number}
     * @memberof DeliveryData
     */
    decreaseInPercent: number;
    /**
     * 
     * @type {number}
     * @memberof DeliveryData
     */
    deliveryCostPerEverybody: number;
    /**
     * 
     * @type {number}
     * @memberof DeliveryData
     */
    deliveryCostPerDish: number;
}
/**
 * 
 * @export
 * @interface DishCreateRequest
 */
export interface DishCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof DishCreateRequest
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof DishCreateRequest
     */
    price: number;
    /**
     * 
     * @type {Array<SideDish>}
     * @memberof DishCreateRequest
     */
    sideDishes: Array<SideDish>;
    /**
     * 
     * @type {string}
     * @memberof DishCreateRequest
     */
    category: string;
}
/**
 * 
 * @export
 * @interface DishDto
 */
export interface DishDto {
    /**
     * 
     * @type {string}
     * @memberof DishDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof DishDto
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof DishDto
     */
    price: number;
    /**
     * 
     * @type {Array<SideDish>}
     * @memberof DishDto
     */
    sideDishes: Array<SideDish>;
    /**
     * 
     * @type {string}
     * @memberof DishDto
     */
    category: string;
    /**
     * 
     * @type {Date}
     * @memberof DishDto
     */
    lastCrawled?: Date;
}
/**
 * 
 * @export
 * @interface DishEntryDishData
 */
export interface DishEntryDishData {
    /**
     * 
     * @type {string}
     * @memberof DishEntryDishData
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof DishEntryDishData
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof DishEntryDishData
     */
    price: number;
}
/**
 * 
 * @export
 * @interface DishEntryDto
 */
export interface DishEntryDto {
    /**
     * 
     * @type {string}
     * @memberof DishEntryDto
     */
    restaurantName: string;
    /**
     * 
     * @type {DishEntryDishData}
     * @memberof DishEntryDto
     */
    dish: DishEntryDishData;
    /**
     * 
     * @type {Array<SideDish>}
     * @memberof DishEntryDto
     */
    chosenSideDishes: Array<SideDish>;
    /**
     * 
     * @type {string}
     * @memberof DishEntryDto
     */
    additionalComments: string;
    /**
     * 
     * @type {string}
     * @memberof DishEntryDto
     */
    id: string;
}
/**
 * 
 * @export
 * @interface DishUpdateRequest
 */
export interface DishUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof DishUpdateRequest
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof DishUpdateRequest
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof DishUpdateRequest
     */
    price: number;
    /**
     * 
     * @type {Array<SideDish>}
     * @memberof DishUpdateRequest
     */
    sideDishes: Array<SideDish>;
    /**
     * 
     * @type {string}
     * @memberof DishUpdateRequest
     */
    category: string;
}
/**
 * 
 * @export
 * @interface EatingPersonEntry
 */
export interface EatingPersonEntry {
    /**
     * 
     * @type {string}
     * @memberof EatingPersonEntry
     */
    username: string;
    /**
     * 
     * @type {string}
     * @memberof EatingPersonEntry
     */
    comments: string;
    /**
     * 
     * @type {Array<SideDish>}
     * @memberof EatingPersonEntry
     */
    sideDishes: Array<SideDish>;
}
/**
 * 
 * @export
 * @interface EditDishResponse
 */
export interface EditDishResponse {
    /**
     * 
     * @type {DishDto}
     * @memberof EditDishResponse
     */
    dish: DishDto;
    /**
     * 
     * @type {Array<string>}
     * @memberof EditDishResponse
     */
    categories: Array<string>;
}
/**
 * 
 * @export
 * @interface EditOrderInitialData
 */
export interface EditOrderInitialData {
    /**
     * 
     * @type {EditOrderInitialDataOrderDto}
     * @memberof EditOrderInitialData
     */
    order: EditOrderInitialDataOrderDto;
}
/**
 * 
 * @export
 * @interface EditOrderInitialDataOrderDto
 */
export interface EditOrderInitialDataOrderDto {
    /**
     * 
     * @type {string}
     * @memberof EditOrderInitialDataOrderDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof EditOrderInitialDataOrderDto
     */
    restaurantId: string;
    /**
     * 
     * @type {string}
     * @memberof EditOrderInitialDataOrderDto
     */
    restaurantName: string;
    /**
     * 
     * @type {string}
     * @memberof EditOrderInitialDataOrderDto
     */
    orderCreatorId: string;
    /**
     * 
     * @type {string}
     * @memberof EditOrderInitialDataOrderDto
     */
    orderCreatorUsername: string;
    /**
     * 
     * @type {string}
     * @memberof EditOrderInitialDataOrderDto
     */
    orderDate: string;
    /**
     * 
     * @type {string}
     * @memberof EditOrderInitialDataOrderDto
     */
    timeOfOrder?: string;
    /**
     * 
     * @type {string}
     * @memberof EditOrderInitialDataOrderDto
     */
    timeOfDelivery?: string;
    /**
     * 
     * @type {string}
     * @memberof EditOrderInitialDataOrderDto
     */
    orderState: EditOrderInitialDataOrderDto.OrderStateEnum;
    /**
     * 
     * @type {DeliveryData}
     * @memberof EditOrderInitialDataOrderDto
     */
    deliveryData: DeliveryData;
    /**
     * 
     * @type {PaymentData}
     * @memberof EditOrderInitialDataOrderDto
     */
    paymentData: PaymentData;
}

/**
 * @export
 * @namespace EditOrderInitialDataOrderDto
 */
export namespace EditOrderInitialDataOrderDto {
    /**
     * @export
     * @enum {string}
     */
    export enum OrderStateEnum {
        CREATED = <any> 'CREATED',
        ORDERING = <any> 'ORDERING',
        ORDERED = <any> 'ORDERED',
        DELIVERED = <any> 'DELIVERED',
        REJECTED = <any> 'REJECTED'
    }
}
/**
 * 
 * @export
 * @interface EditRestaurantResponse
 */
export interface EditRestaurantResponse {
    /**
     * 
     * @type {string}
     * @memberof EditRestaurantResponse
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof EditRestaurantResponse
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof EditRestaurantResponse
     */
    address: string;
    /**
     * 
     * @type {string}
     * @memberof EditRestaurantResponse
     */
    telephone: string;
    /**
     * 
     * @type {string}
     * @memberof EditRestaurantResponse
     */
    url: string;
}
/**
 * 
 * @export
 * @interface GoogleAuthError
 */
export interface GoogleAuthError {
    /**
     * 
     * @type {string}
     * @memberof GoogleAuthError
     */
    message: string;
}
/**
 * 
 * @export
 * @interface GoogleAuthSuccess
 */
export interface GoogleAuthSuccess {
    /**
     * 
     * @type {AuthUserInfo}
     * @memberof GoogleAuthSuccess
     */
    userInfo: AuthUserInfo;
}
/**
 * 
 * @export
 * @interface GooglePayload
 */
export interface GooglePayload {
    /**
     * 
     * @type {string}
     * @memberof GooglePayload
     */
    credential: string;
    /**
     * 
     * @type {string}
     * @memberof GooglePayload
     */
    clientId: string;
    /**
     * 
     * @type {string}
     * @memberof GooglePayload
     */
    selectBy: string;
}
/**
 * 
 * @export
 * @interface GroupedOrderEntry
 */
export interface GroupedOrderEntry {
    /**
     * 
     * @type {string}
     * @memberof GroupedOrderEntry
     */
    dishName: string;
    /**
     * 
     * @type {number}
     * @memberof GroupedOrderEntry
     */
    price: number;
    /**
     * 
     * @type {number}
     * @memberof GroupedOrderEntry
     */
    eatingPeopleCount: number;
    /**
     * 
     * @type {Array<EatingPersonEntry>}
     * @memberof GroupedOrderEntry
     */
    eatingPeopleEntries: Array<EatingPersonEntry>;
}
/**
 * 
 * @export
 * @interface ImportCredentials
 */
export interface ImportCredentials {
    /**
     * 
     * @type {string}
     * @memberof ImportCredentials
     */
    apiKey: string;
}
/**
 * 
 * @export
 * @interface IndexResponse
 */
export interface IndexResponse {
    /**
     * 
     * @type {Array<RestaurantInfo>}
     * @memberof IndexResponse
     */
    restaurants: Array<RestaurantInfo>;
    /**
     * 
     * @type {ImportCredentials}
     * @memberof IndexResponse
     */
    importCredentials: ImportCredentials;
}
/**
 * 
 * @export
 * @interface LocalTime
 */
export interface LocalTime {
    /**
     * 
     * @type {number}
     * @memberof LocalTime
     */
    hour?: number;
    /**
     * 
     * @type {number}
     * @memberof LocalTime
     */
    minute?: number;
    /**
     * 
     * @type {number}
     * @memberof LocalTime
     */
    second?: number;
    /**
     * 
     * @type {number}
     * @memberof LocalTime
     */
    nano?: number;
}
/**
 * 
 * @export
 * @interface OrderEntryDto
 */
export interface OrderEntryDto {
    /**
     * 
     * @type {string}
     * @memberof OrderEntryDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof OrderEntryDto
     */
    orderId: string;
    /**
     * 
     * @type {string}
     * @memberof OrderEntryDto
     */
    orderState: OrderEntryDto.OrderStateEnum;
    /**
     * 
     * @type {Array<DishEntryDto>}
     * @memberof OrderEntryDto
     */
    dishEntries: Array<DishEntryDto>;
    /**
     * 
     * @type {string}
     * @memberof OrderEntryDto
     */
    paymentStatus: OrderEntryDto.PaymentStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof OrderEntryDto
     */
    created: string;
}

/**
 * @export
 * @namespace OrderEntryDto
 */
export namespace OrderEntryDto {
    /**
     * @export
     * @enum {string}
     */
    export enum OrderStateEnum {
        CREATED = <any> 'CREATED',
        ORDERING = <any> 'ORDERING',
        ORDERED = <any> 'ORDERED',
        DELIVERED = <any> 'DELIVERED',
        REJECTED = <any> 'REJECTED'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum PaymentStatusEnum {
        UNPAID = <any> 'UNPAID',
        CONFIRMED = <any> 'CONFIRMED'
    }
}
/**
 * 
 * @export
 * @interface OrderEntrySaveRequest
 */
export interface OrderEntrySaveRequest {
    /**
     * 
     * @type {string}
     * @memberof OrderEntrySaveRequest
     */
    orderId: string;
    /**
     * 
     * @type {string}
     * @memberof OrderEntrySaveRequest
     */
    dishId?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderEntrySaveRequest
     */
    additionalComments: string;
    /**
     * 
     * @type {boolean}
     * @memberof OrderEntrySaveRequest
     */
    newDish?: boolean;
    /**
     * 
     * @type {string}
     * @memberof OrderEntrySaveRequest
     */
    newDishName?: string;
    /**
     * 
     * @type {number}
     * @memberof OrderEntrySaveRequest
     */
    newDishPrice?: number;
    /**
     * 
     * @type {Array<SideDishData>}
     * @memberof OrderEntrySaveRequest
     */
    sideDishes: Array<SideDishData>;
}
/**
 * 
 * @export
 * @interface OrderEntryUpdateRequest
 */
export interface OrderEntryUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof OrderEntryUpdateRequest
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderEntryUpdateRequest
     */
    dishEntryId?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderEntryUpdateRequest
     */
    orderId?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderEntryUpdateRequest
     */
    dishId?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderEntryUpdateRequest
     */
    additionalComments: string;
    /**
     * 
     * @type {boolean}
     * @memberof OrderEntryUpdateRequest
     */
    newDish?: boolean;
    /**
     * 
     * @type {string}
     * @memberof OrderEntryUpdateRequest
     */
    newDishName?: string;
    /**
     * 
     * @type {number}
     * @memberof OrderEntryUpdateRequest
     */
    newDishPrice?: number;
    /**
     * 
     * @type {Array<SideDishData>}
     * @memberof OrderEntryUpdateRequest
     */
    sideDishes: Array<SideDishData>;
}
/**
 * 
 * @export
 * @interface OrderHistory
 */
export interface OrderHistory {
    /**
     * 
     * @type {Array<OrderHistoryCreatedEntry | OrderHistoryParticipatedEntry>}
     * @memberof OrderHistory
     */
    entries: Array<OrderHistoryCreatedEntry | OrderHistoryParticipatedEntry>;
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof OrderHistory
     */
    owedMoney: { [key: string]: number; };
}
/**
 * 
 * @export
 * @interface OrderHistoryCreatedEntry
 */
export interface OrderHistoryCreatedEntry extends OrderHistoryEntry {
    /**
     * 
     * @type {number}
     * @memberof OrderHistoryCreatedEntry
     */
    confirmedPaymentsTotalAmount: number;
    /**
     * 
     * @type {number}
     * @memberof OrderHistoryCreatedEntry
     */
    totalAmount: number;
}
/**
 * 
 * @export
 * @interface OrderHistoryEntry
 */
export interface OrderHistoryEntry {
    /**
     * 
     * @type {string}
     * @memberof OrderHistoryEntry
     */
    orderId: string;
    /**
     * 
     * @type {string}
     * @memberof OrderHistoryEntry
     */
    kind: string;
    /**
     * 
     * @type {string}
     * @memberof OrderHistoryEntry
     */
    orderDate: string;
    /**
     * 
     * @type {string}
     * @memberof OrderHistoryEntry
     */
    orderCreator: string;
    /**
     * 
     * @type {string}
     * @memberof OrderHistoryEntry
     */
    restaurantName: string;
}
/**
 * 
 * @export
 * @interface OrderHistoryParticipatedEntry
 */
export interface OrderHistoryParticipatedEntry extends OrderHistoryEntry {
    /**
     * 
     * @type {number}
     * @memberof OrderHistoryParticipatedEntry
     */
    orderEntryAmount: number;
    /**
     * 
     * @type {string}
     * @memberof OrderHistoryParticipatedEntry
     */
    status: OrderHistoryParticipatedEntry.StatusEnum;
}

/**
 * @export
 * @namespace OrderHistoryParticipatedEntry
 */
export namespace OrderHistoryParticipatedEntry {
    /**
     * @export
     * @enum {string}
     */
    export enum StatusEnum {
        UNPAID = <any> 'UNPAID',
        CONFIRMED = <any> 'CONFIRMED'
    }
}
/**
 * 
 * @export
 * @interface OrderSaveRequest
 */
export interface OrderSaveRequest {
    /**
     * 
     * @type {string}
     * @memberof OrderSaveRequest
     */
    restaurantId?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderSaveRequest
     */
    orderDate?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderSaveRequest
     */
    timeOfOrder?: string;
    /**
     * 
     * @type {DeliveryData}
     * @memberof OrderSaveRequest
     */
    deliveryData: DeliveryData;
    /**
     * 
     * @type {PaymentData}
     * @memberof OrderSaveRequest
     */
    paymentData: PaymentData;
}
/**
 * 
 * @export
 * @interface OrderUpdateRequest
 */
export interface OrderUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof OrderUpdateRequest
     */
    orderId?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderUpdateRequest
     */
    orderDate?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderUpdateRequest
     */
    timeOfOrder?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderUpdateRequest
     */
    timeOfDelivery?: string;
    /**
     * 
     * @type {DeliveryData}
     * @memberof OrderUpdateRequest
     */
    deliveryData: DeliveryData;
    /**
     * 
     * @type {PaymentData}
     * @memberof OrderUpdateRequest
     */
    paymentData: PaymentData;
}
/**
 * 
 * @export
 * @interface OrderViewInitialData
 */
export interface OrderViewInitialData {
    /**
     * 
     * @type {string}
     * @memberof OrderViewInitialData
     */
    orderState: OrderViewInitialData.OrderStateEnum;
    /**
     * 
     * @type {DeliveryData}
     * @memberof OrderViewInitialData
     */
    orderDeliveryData: DeliveryData;
    /**
     * 
     * @type {string}
     * @memberof OrderViewInitialData
     */
    restaurantName: string;
    /**
     * 
     * @type {string}
     * @memberof OrderViewInitialData
     */
    restaurantTelephone: string;
    /**
     * 
     * @type {Array<GroupedOrderEntry>}
     * @memberof OrderViewInitialData
     */
    groupedEntries: Array<GroupedOrderEntry>;
    /**
     * 
     * @type {number}
     * @memberof OrderViewInitialData
     */
    allEatingPeopleCount: number;
    /**
     * 
     * @type {number}
     * @memberof OrderViewInitialData
     */
    basePriceSum: number;
    /**
     * 
     * @type {number}
     * @memberof OrderViewInitialData
     */
    totalPrice: number;
}

/**
 * @export
 * @namespace OrderViewInitialData
 */
export namespace OrderViewInitialData {
    /**
     * @export
     * @enum {string}
     */
    export enum OrderStateEnum {
        CREATED = <any> 'CREATED',
        ORDERING = <any> 'ORDERING',
        ORDERED = <any> 'ORDERED',
        DELIVERED = <any> 'DELIVERED',
        REJECTED = <any> 'REJECTED'
    }
}
/**
 * 
 * @export
 * @interface ParticipantsDishEntry
 */
export interface ParticipantsDishEntry {
    /**
     * 
     * @type {string}
     * @memberof ParticipantsDishEntry
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ParticipantsDishEntry
     */
    dishId: string;
    /**
     * 
     * @type {string}
     * @memberof ParticipantsDishEntry
     */
    dishName: string;
    /**
     * 
     * @type {Array<SideDish>}
     * @memberof ParticipantsDishEntry
     */
    sideDishes: Array<SideDish>;
    /**
     * 
     * @type {number}
     * @memberof ParticipantsDishEntry
     */
    price: number;
    /**
     * 
     * @type {string}
     * @memberof ParticipantsDishEntry
     */
    comments: string;
}
/**
 * 
 * @export
 * @interface ParticipantsOrderEntry
 */
export interface ParticipantsOrderEntry {
    /**
     * 
     * @type {string}
     * @memberof ParticipantsOrderEntry
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ParticipantsOrderEntry
     */
    userId: string;
    /**
     * 
     * @type {string}
     * @memberof ParticipantsOrderEntry
     */
    username: string;
    /**
     * 
     * @type {Array<ParticipantsDishEntry>}
     * @memberof ParticipantsOrderEntry
     */
    dishEntries: Array<ParticipantsDishEntry>;
    /**
     * 
     * @type {number}
     * @memberof ParticipantsOrderEntry
     */
    finalPrice: number;
    /**
     * 
     * @type {string}
     * @memberof ParticipantsOrderEntry
     */
    paymentStatus: ParticipantsOrderEntry.PaymentStatusEnum;
}

/**
 * @export
 * @namespace ParticipantsOrderEntry
 */
export namespace ParticipantsOrderEntry {
    /**
     * @export
     * @enum {string}
     */
    export enum PaymentStatusEnum {
        UNPAID = <any> 'UNPAID',
        CONFIRMED = <any> 'CONFIRMED'
    }
}
/**
 * 
 * @export
 * @interface PaymentData
 */
export interface PaymentData {
    /**
     * 
     * @type {boolean}
     * @memberof PaymentData
     */
    paymentByCash: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PaymentData
     */
    paymentByBankTransfer: boolean;
    /**
     * 
     * @type {string}
     * @memberof PaymentData
     */
    bankTransferNumber: string;
    /**
     * 
     * @type {boolean}
     * @memberof PaymentData
     */
    paymentByBlik: boolean;
    /**
     * 
     * @type {string}
     * @memberof PaymentData
     */
    blikPhoneNumber: string;
}
/**
 * 
 * @export
 * @interface PushNotifSubscriptionData
 */
export interface PushNotifSubscriptionData {
    /**
     * 
     * @type {string}
     * @memberof PushNotifSubscriptionData
     */
    endpoint: string;
    /**
     * 
     * @type {string}
     * @memberof PushNotifSubscriptionData
     */
    p256dhKey: string;
    /**
     * 
     * @type {string}
     * @memberof PushNotifSubscriptionData
     */
    authKey: string;
}
/**
 * 
 * @export
 * @interface RestaurantDto
 */
export interface RestaurantDto {
    /**
     * 
     * @type {string}
     * @memberof RestaurantDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof RestaurantDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof RestaurantDto
     */
    telephone: string;
    /**
     * 
     * @type {string}
     * @memberof RestaurantDto
     */
    address: string;
    /**
     * 
     * @type {string}
     * @memberof RestaurantDto
     */
    url: string;
    /**
     * 
     * @type {Date}
     * @memberof RestaurantDto
     */
    lastCrawled?: Date;
    /**
     * 
     * @type {Date}
     * @memberof RestaurantDto
     */
    lastEdited?: Date;
}
/**
 * 
 * @export
 * @interface RestaurantInfo
 */
export interface RestaurantInfo {
    /**
     * 
     * @type {string}
     * @memberof RestaurantInfo
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof RestaurantInfo
     */
    name: string;
    /**
     * 
     * @type {Date}
     * @memberof RestaurantInfo
     */
    lastCrawled?: Date;
    /**
     * 
     * @type {Date}
     * @memberof RestaurantInfo
     */
    lastEdited?: Date;
    /**
     * 
     * @type {number}
     * @memberof RestaurantInfo
     */
    dishCount: number;
}
/**
 * 
 * @export
 * @interface RestaurantSaveRequest
 */
export interface RestaurantSaveRequest {
    /**
     * 
     * @type {string}
     * @memberof RestaurantSaveRequest
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof RestaurantSaveRequest
     */
    telephone: string;
    /**
     * 
     * @type {string}
     * @memberof RestaurantSaveRequest
     */
    address: string;
    /**
     * 
     * @type {string}
     * @memberof RestaurantSaveRequest
     */
    url: string;
}
/**
 * 
 * @export
 * @interface RestaurantUpdateRequest
 */
export interface RestaurantUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof RestaurantUpdateRequest
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof RestaurantUpdateRequest
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof RestaurantUpdateRequest
     */
    telephone: string;
    /**
     * 
     * @type {string}
     * @memberof RestaurantUpdateRequest
     */
    address: string;
    /**
     * 
     * @type {string}
     * @memberof RestaurantUpdateRequest
     */
    url: string;
}
/**
 * 
 * @export
 * @interface SetAsOrderedResponse
 */
export interface SetAsOrderedResponse {
    /**
     * 
     * @type {string}
     * @memberof SetAsOrderedResponse
     */
    approxTimeOfDelivery?: string;
}
/**
 * 
 * @export
 * @interface ShowOrderDto
 */
export interface ShowOrderDto {
    /**
     * 
     * @type {string}
     * @memberof ShowOrderDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ShowOrderDto
     */
    restaurantId: string;
    /**
     * 
     * @type {string}
     * @memberof ShowOrderDto
     */
    restaurantName: string;
    /**
     * 
     * @type {string}
     * @memberof ShowOrderDto
     */
    restaurantUrl: string;
    /**
     * 
     * @type {string}
     * @memberof ShowOrderDto
     */
    orderCreatorId: string;
    /**
     * 
     * @type {string}
     * @memberof ShowOrderDto
     */
    orderCreatorUsername: string;
    /**
     * 
     * @type {string}
     * @memberof ShowOrderDto
     */
    orderDate: string;
    /**
     * 
     * @type {string}
     * @memberof ShowOrderDto
     */
    timeOfOrder?: string;
    /**
     * 
     * @type {string}
     * @memberof ShowOrderDto
     */
    timeOfDelivery?: string;
    /**
     * 
     * @type {string}
     * @memberof ShowOrderDto
     */
    orderState: ShowOrderDto.OrderStateEnum;
    /**
     * 
     * @type {DeliveryData}
     * @memberof ShowOrderDto
     */
    deliveryData: DeliveryData;
    /**
     * 
     * @type {PaymentData}
     * @memberof ShowOrderDto
     */
    paymentData: PaymentData;
}

/**
 * @export
 * @namespace ShowOrderDto
 */
export namespace ShowOrderDto {
    /**
     * @export
     * @enum {string}
     */
    export enum OrderStateEnum {
        CREATED = <any> 'CREATED',
        ORDERING = <any> 'ORDERING',
        ORDERED = <any> 'ORDERED',
        DELIVERED = <any> 'DELIVERED',
        REJECTED = <any> 'REJECTED'
    }
}
/**
 * 
 * @export
 * @interface ShowOrderResponse
 */
export interface ShowOrderResponse {
    /**
     * 
     * @type {ShowOrderDto}
     * @memberof ShowOrderResponse
     */
    order: ShowOrderDto;
    /**
     * 
     * @type {Array<ParticipantsOrderEntry>}
     * @memberof ShowOrderResponse
     */
    orderEntries: Array<ParticipantsOrderEntry>;
    /**
     * 
     * @type {string}
     * @memberof ShowOrderResponse
     */
    currentUserId: string;
    /**
     * 
     * @type {Array<DishDto>}
     * @memberof ShowOrderResponse
     */
    allDishesInRestaurant: Array<DishDto>;
    /**
     * 
     * @type {{ [key: string]: Array<DishDto>; }}
     * @memberof ShowOrderResponse
     */
    allDishesByCategory: { [key: string]: Array<DishDto>; };
    /**
     * 
     * @type {{ [key: string]: Array<SideDish>; }}
     * @memberof ShowOrderResponse
     */
    dishIdToSideDishesMap: { [key: string]: Array<SideDish>; };
    /**
     * 
     * @type {number}
     * @memberof ShowOrderResponse
     */
    baseOrderPrice: number;
    /**
     * 
     * @type {number}
     * @memberof ShowOrderResponse
     */
    totalOrderPrice: number;
}
/**
 * 
 * @export
 * @interface ShowRestaurantResponse
 */
export interface ShowRestaurantResponse {
    /**
     * 
     * @type {RestaurantDto}
     * @memberof ShowRestaurantResponse
     */
    restaurant: RestaurantDto;
    /**
     * 
     * @type {Array<DishDto>}
     * @memberof ShowRestaurantResponse
     */
    dishes: Array<DishDto>;
    /**
     * 
     * @type {{ [key: string]: Array<DishDto>; }}
     * @memberof ShowRestaurantResponse
     */
    dishesByCategory: { [key: string]: Array<DishDto>; };
}
/**
 * 
 * @export
 * @interface SideDish
 */
export interface SideDish {
    /**
     * 
     * @type {string}
     * @memberof SideDish
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof SideDish
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof SideDish
     */
    price: number;
}
/**
 * 
 * @export
 * @interface SideDishData
 */
export interface SideDishData {
    /**
     * 
     * @type {boolean}
     * @memberof SideDishData
     */
    isNew?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SideDishData
     */
    newSideDishName?: string;
    /**
     * 
     * @type {number}
     * @memberof SideDishData
     */
    newSideDishPrice?: number;
    /**
     * 
     * @type {string}
     * @memberof SideDishData
     */
    id?: string;
}
/**
 * 
 * @export
 * @interface SseEmitter
 */
export interface SseEmitter {
    /**
     * 
     * @type {number}
     * @memberof SseEmitter
     */
    timeout?: number;
}
/**
 * 
 * @export
 * @interface TodayOrderDto
 */
export interface TodayOrderDto {
    /**
     * 
     * @type {string}
     * @memberof TodayOrderDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof TodayOrderDto
     */
    restaurantId: string;
    /**
     * 
     * @type {string}
     * @memberof TodayOrderDto
     */
    restaurantName: string;
    /**
     * 
     * @type {string}
     * @memberof TodayOrderDto
     */
    orderCreatorId: string;
    /**
     * 
     * @type {string}
     * @memberof TodayOrderDto
     */
    orderCreatorUsername: string;
    /**
     * 
     * @type {string}
     * @memberof TodayOrderDto
     */
    orderDate: string;
    /**
     * 
     * @type {string}
     * @memberof TodayOrderDto
     */
    timeOfOrder?: string;
    /**
     * 
     * @type {LocalTime}
     * @memberof TodayOrderDto
     */
    timeOfDelivery?: LocalTime;
    /**
     * 
     * @type {string}
     * @memberof TodayOrderDto
     */
    orderState: TodayOrderDto.OrderStateEnum;
    /**
     * 
     * @type {number}
     * @memberof TodayOrderDto
     */
    decreaseInPercent: number;
    /**
     * 
     * @type {number}
     * @memberof TodayOrderDto
     */
    deliveryCostPerEverybody: number;
    /**
     * 
     * @type {number}
     * @memberof TodayOrderDto
     */
    deliveryCostPerDish: number;
    /**
     * 
     * @type {boolean}
     * @memberof TodayOrderDto
     */
    paymentByCash: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TodayOrderDto
     */
    paymentByBankTransfer: boolean;
    /**
     * 
     * @type {string}
     * @memberof TodayOrderDto
     */
    bankTransferNumber: string;
}

/**
 * @export
 * @namespace TodayOrderDto
 */
export namespace TodayOrderDto {
    /**
     * @export
     * @enum {string}
     */
    export enum OrderStateEnum {
        CREATED = <any> 'CREATED',
        ORDERING = <any> 'ORDERING',
        ORDERED = <any> 'ORDERED',
        DELIVERED = <any> 'DELIVERED',
        REJECTED = <any> 'REJECTED'
    }
}
/**
 * 
 * @export
 * @interface TodayOrdersResponse
 */
export interface TodayOrdersResponse {
    /**
     * 
     * @type {Array<TodayOrderDto>}
     * @memberof TodayOrdersResponse
     */
    ordersList: Array<TodayOrderDto>;
    /**
     * 
     * @type {Array<OrderEntryDto>}
     * @memberof TodayOrdersResponse
     */
    currentOrderEntries: Array<OrderEntryDto>;
}
/**
 * AuthControllerApi - fetch parameter creator
 * @export
 */
export const AuthControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {GooglePayload} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginWithReceivedJwt(body: GooglePayload, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling loginWithReceivedJwt.');
            }
            const localVarPath = `/api/auth/googleLogin/authorizationCode`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"GooglePayload" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthControllerApi - functional programming interface
 * @export
 */
export const AuthControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {GooglePayload} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginWithReceivedJwt(body: GooglePayload, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<GoogleAuthSuccess> {
            const localVarFetchArgs = AuthControllerApiFetchParamCreator(configuration).loginWithReceivedJwt(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * AuthControllerApi - factory interface
 * @export
 */
export const AuthControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {GooglePayload} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginWithReceivedJwt(body: GooglePayload, options?: any) {
            return AuthControllerApiFp(configuration).loginWithReceivedJwt(body, options)(fetch, basePath);
        },
    };
};

/**
 * AuthControllerApi - object-oriented interface
 * @export
 * @class AuthControllerApi
 * @extends {BaseAPI}
 */
export class AuthControllerApi extends BaseAPI {
    /**
     * 
     * @param {GooglePayload} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthControllerApi
     */
    public loginWithReceivedJwt(body: GooglePayload, options?: any) {
        return AuthControllerApiFp(this.configuration).loginWithReceivedJwt(body, options)(this.fetch, this.basePath);
    }

}
/**
 * BalanceControllerApi - fetch parameter creator
 * @export
 */
export const BalanceControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBalanceForUser(options: any = {}): FetchArgs {
            const localVarPath = `/api/balance/getForUser`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BalanceControllerApi - functional programming interface
 * @export
 */
export const BalanceControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBalanceForUser(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<OrderHistory> {
            const localVarFetchArgs = BalanceControllerApiFetchParamCreator(configuration).getBalanceForUser(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * BalanceControllerApi - factory interface
 * @export
 */
export const BalanceControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBalanceForUser(options?: any) {
            return BalanceControllerApiFp(configuration).getBalanceForUser(options)(fetch, basePath);
        },
    };
};

/**
 * BalanceControllerApi - object-oriented interface
 * @export
 * @class BalanceControllerApi
 * @extends {BaseAPI}
 */
export class BalanceControllerApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BalanceControllerApi
     */
    public getBalanceForUser(options?: any) {
        return BalanceControllerApiFp(this.configuration).getBalanceForUser(options)(this.fetch, this.basePath);
    }

}
/**
 * DishControllerApi - fetch parameter creator
 * @export
 */
export const DishControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} restaurantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDish(restaurantId: string, options: any = {}): FetchArgs {
            // verify required parameter 'restaurantId' is not null or undefined
            if (restaurantId === null || restaurantId === undefined) {
                throw new RequiredError('restaurantId','Required parameter restaurantId was null or undefined when calling createDish.');
            }
            const localVarPath = `/api/restaurants/{restaurantId}/dishes/create.json`
                .replace(`{${"restaurantId"}}`, encodeURIComponent(String(restaurantId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} dishId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDish(dishId: string, options: any = {}): FetchArgs {
            // verify required parameter 'dishId' is not null or undefined
            if (dishId === null || dishId === undefined) {
                throw new RequiredError('dishId','Required parameter dishId was null or undefined when calling deleteDish.');
            }
            const localVarPath = `/api/dishes/{dishId}/delete`
                .replace(`{${"dishId"}}`, encodeURIComponent(String(dishId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} dishId 
         * @param {string} sideDishId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSideDish(dishId: string, sideDishId: string, options: any = {}): FetchArgs {
            // verify required parameter 'dishId' is not null or undefined
            if (dishId === null || dishId === undefined) {
                throw new RequiredError('dishId','Required parameter dishId was null or undefined when calling deleteSideDish.');
            }
            // verify required parameter 'sideDishId' is not null or undefined
            if (sideDishId === null || sideDishId === undefined) {
                throw new RequiredError('sideDishId','Required parameter sideDishId was null or undefined when calling deleteSideDish.');
            }
            const localVarPath = `/api/dishes/{dishId}/side_dishes/{sideDishId}/delete`
                .replace(`{${"dishId"}}`, encodeURIComponent(String(dishId)))
                .replace(`{${"sideDishId"}}`, encodeURIComponent(String(sideDishId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} restaurantId 
         * @param {string} dishId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editDish(restaurantId: string, dishId: string, options: any = {}): FetchArgs {
            // verify required parameter 'restaurantId' is not null or undefined
            if (restaurantId === null || restaurantId === undefined) {
                throw new RequiredError('restaurantId','Required parameter restaurantId was null or undefined when calling editDish.');
            }
            // verify required parameter 'dishId' is not null or undefined
            if (dishId === null || dishId === undefined) {
                throw new RequiredError('dishId','Required parameter dishId was null or undefined when calling editDish.');
            }
            const localVarPath = `/api/restaurants/{restaurantId}/dishes/{dishId}/edit.json`
                .replace(`{${"restaurantId"}}`, encodeURIComponent(String(restaurantId)))
                .replace(`{${"dishId"}}`, encodeURIComponent(String(dishId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DishCreateRequest} body 
         * @param {string} restaurantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveDish(body: DishCreateRequest, restaurantId: string, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling saveDish.');
            }
            // verify required parameter 'restaurantId' is not null or undefined
            if (restaurantId === null || restaurantId === undefined) {
                throw new RequiredError('restaurantId','Required parameter restaurantId was null or undefined when calling saveDish.');
            }
            const localVarPath = `/api/restaurants/{restaurantId}/dishes/save`
                .replace(`{${"restaurantId"}}`, encodeURIComponent(String(restaurantId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"DishCreateRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DishUpdateRequest} body 
         * @param {string} restaurantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDish(body: DishUpdateRequest, restaurantId: string, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateDish.');
            }
            // verify required parameter 'restaurantId' is not null or undefined
            if (restaurantId === null || restaurantId === undefined) {
                throw new RequiredError('restaurantId','Required parameter restaurantId was null or undefined when calling updateDish.');
            }
            const localVarPath = `/api/restaurants/{restaurantId}/dishes/update`
                .replace(`{${"restaurantId"}}`, encodeURIComponent(String(restaurantId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"DishUpdateRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DishControllerApi - functional programming interface
 * @export
 */
export const DishControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} restaurantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDish(restaurantId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CreateDishResponse> {
            const localVarFetchArgs = DishControllerApiFetchParamCreator(configuration).createDish(restaurantId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} dishId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDish(dishId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<string> {
            const localVarFetchArgs = DishControllerApiFetchParamCreator(configuration).deleteDish(dishId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} dishId 
         * @param {string} sideDishId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSideDish(dishId: string, sideDishId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<string> {
            const localVarFetchArgs = DishControllerApiFetchParamCreator(configuration).deleteSideDish(dishId, sideDishId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} restaurantId 
         * @param {string} dishId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editDish(restaurantId: string, dishId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<EditDishResponse> {
            const localVarFetchArgs = DishControllerApiFetchParamCreator(configuration).editDish(restaurantId, dishId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {DishCreateRequest} body 
         * @param {string} restaurantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveDish(body: DishCreateRequest, restaurantId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<string> {
            const localVarFetchArgs = DishControllerApiFetchParamCreator(configuration).saveDish(body, restaurantId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {DishUpdateRequest} body 
         * @param {string} restaurantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDish(body: DishUpdateRequest, restaurantId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<string> {
            const localVarFetchArgs = DishControllerApiFetchParamCreator(configuration).updateDish(body, restaurantId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * DishControllerApi - factory interface
 * @export
 */
export const DishControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {string} restaurantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDish(restaurantId: string, options?: any) {
            return DishControllerApiFp(configuration).createDish(restaurantId, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} dishId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDish(dishId: string, options?: any) {
            return DishControllerApiFp(configuration).deleteDish(dishId, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} dishId 
         * @param {string} sideDishId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSideDish(dishId: string, sideDishId: string, options?: any) {
            return DishControllerApiFp(configuration).deleteSideDish(dishId, sideDishId, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} restaurantId 
         * @param {string} dishId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editDish(restaurantId: string, dishId: string, options?: any) {
            return DishControllerApiFp(configuration).editDish(restaurantId, dishId, options)(fetch, basePath);
        },
        /**
         * 
         * @param {DishCreateRequest} body 
         * @param {string} restaurantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveDish(body: DishCreateRequest, restaurantId: string, options?: any) {
            return DishControllerApiFp(configuration).saveDish(body, restaurantId, options)(fetch, basePath);
        },
        /**
         * 
         * @param {DishUpdateRequest} body 
         * @param {string} restaurantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDish(body: DishUpdateRequest, restaurantId: string, options?: any) {
            return DishControllerApiFp(configuration).updateDish(body, restaurantId, options)(fetch, basePath);
        },
    };
};

/**
 * DishControllerApi - object-oriented interface
 * @export
 * @class DishControllerApi
 * @extends {BaseAPI}
 */
export class DishControllerApi extends BaseAPI {
    /**
     * 
     * @param {string} restaurantId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DishControllerApi
     */
    public createDish(restaurantId: string, options?: any) {
        return DishControllerApiFp(this.configuration).createDish(restaurantId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} dishId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DishControllerApi
     */
    public deleteDish(dishId: string, options?: any) {
        return DishControllerApiFp(this.configuration).deleteDish(dishId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} dishId 
     * @param {string} sideDishId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DishControllerApi
     */
    public deleteSideDish(dishId: string, sideDishId: string, options?: any) {
        return DishControllerApiFp(this.configuration).deleteSideDish(dishId, sideDishId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} restaurantId 
     * @param {string} dishId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DishControllerApi
     */
    public editDish(restaurantId: string, dishId: string, options?: any) {
        return DishControllerApiFp(this.configuration).editDish(restaurantId, dishId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {DishCreateRequest} body 
     * @param {string} restaurantId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DishControllerApi
     */
    public saveDish(body: DishCreateRequest, restaurantId: string, options?: any) {
        return DishControllerApiFp(this.configuration).saveDish(body, restaurantId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {DishUpdateRequest} body 
     * @param {string} restaurantId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DishControllerApi
     */
    public updateDish(body: DishUpdateRequest, restaurantId: string, options?: any) {
        return DishControllerApiFp(this.configuration).updateDish(body, restaurantId, options)(this.fetch, this.basePath);
    }

}
/**
 * NotificationControllerApi - fetch parameter creator
 * @export
 */
export const NotificationControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {PushNotifSubscriptionData} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addSubscriber(body: PushNotifSubscriptionData, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling addSubscriber.');
            }
            const localVarPath = `/api/notification/subscribe`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"PushNotifSubscriptionData" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeSubscriber(options: any = {}): FetchArgs {
            const localVarPath = `/api/notification/unsubscribe`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NotificationControllerApi - functional programming interface
 * @export
 */
export const NotificationControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {PushNotifSubscriptionData} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addSubscriber(body: PushNotifSubscriptionData, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<string> {
            const localVarFetchArgs = NotificationControllerApiFetchParamCreator(configuration).addSubscriber(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeSubscriber(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<string> {
            const localVarFetchArgs = NotificationControllerApiFetchParamCreator(configuration).removeSubscriber(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * NotificationControllerApi - factory interface
 * @export
 */
export const NotificationControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {PushNotifSubscriptionData} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addSubscriber(body: PushNotifSubscriptionData, options?: any) {
            return NotificationControllerApiFp(configuration).addSubscriber(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeSubscriber(options?: any) {
            return NotificationControllerApiFp(configuration).removeSubscriber(options)(fetch, basePath);
        },
    };
};

/**
 * NotificationControllerApi - object-oriented interface
 * @export
 * @class NotificationControllerApi
 * @extends {BaseAPI}
 */
export class NotificationControllerApi extends BaseAPI {
    /**
     * 
     * @param {PushNotifSubscriptionData} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationControllerApi
     */
    public addSubscriber(body: PushNotifSubscriptionData, options?: any) {
        return NotificationControllerApiFp(this.configuration).addSubscriber(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationControllerApi
     */
    public removeSubscriber(options?: any) {
        return NotificationControllerApiFp(this.configuration).removeSubscriber(options)(this.fetch, this.basePath);
    }

}
/**
 * OrderControllerApi - fetch parameter creator
 * @export
 */
export const OrderControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _delete(orderId: string, options: any = {}): FetchArgs {
            // verify required parameter 'orderId' is not null or undefined
            if (orderId === null || orderId === undefined) {
                throw new RequiredError('orderId','Required parameter orderId was null or undefined when calling _delete.');
            }
            const localVarPath = `/api/orders/{orderId}/delete`
                .replace(`{${"orderId"}}`, encodeURIComponent(String(orderId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allOrders(options: any = {}): FetchArgs {
            const localVarPath = `/api/orders/all.json`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create(options: any = {}): FetchArgs {
            const localVarPath = `/api/orders/create.json`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        edit(orderId: string, options: any = {}): FetchArgs {
            // verify required parameter 'orderId' is not null or undefined
            if (orderId === null || orderId === undefined) {
                throw new RequiredError('orderId','Required parameter orderId was null or undefined when calling edit.');
            }
            const localVarPath = `/api/orders/{orderId}/edit.json`
                .replace(`{${"orderId"}}`, encodeURIComponent(String(orderId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        handleSse(options: any = {}): FetchArgs {
            const localVarPath = `/api/orders/sse`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderViewJson(orderId: string, options: any = {}): FetchArgs {
            // verify required parameter 'orderId' is not null or undefined
            if (orderId === null || orderId === undefined) {
                throw new RequiredError('orderId','Required parameter orderId was null or undefined when calling orderViewJson.');
            }
            const localVarPath = `/api/orders/{orderId}/order_view.json`
                .replace(`{${"orderId"}}`, encodeURIComponent(String(orderId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {OrderSaveRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        save(body: OrderSaveRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling save.');
            }
            const localVarPath = `/api/orders/save`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"OrderSaveRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setAsCreated(orderId: string, options: any = {}): FetchArgs {
            // verify required parameter 'orderId' is not null or undefined
            if (orderId === null || orderId === undefined) {
                throw new RequiredError('orderId','Required parameter orderId was null or undefined when calling setAsCreated.');
            }
            const localVarPath = `/api/orders/{orderId}/set_as_created`
                .replace(`{${"orderId"}}`, encodeURIComponent(String(orderId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setAsDelivered(orderId: string, options: any = {}): FetchArgs {
            // verify required parameter 'orderId' is not null or undefined
            if (orderId === null || orderId === undefined) {
                throw new RequiredError('orderId','Required parameter orderId was null or undefined when calling setAsDelivered.');
            }
            const localVarPath = `/api/orders/{orderId}/set_as_delivered`
                .replace(`{${"orderId"}}`, encodeURIComponent(String(orderId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SetAsOrderedResponse} body 
         * @param {string} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setAsOrdered(body: SetAsOrderedResponse, orderId: string, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling setAsOrdered.');
            }
            // verify required parameter 'orderId' is not null or undefined
            if (orderId === null || orderId === undefined) {
                throw new RequiredError('orderId','Required parameter orderId was null or undefined when calling setAsOrdered.');
            }
            const localVarPath = `/api/orders/{orderId}/set_as_ordered`
                .replace(`{${"orderId"}}`, encodeURIComponent(String(orderId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"SetAsOrderedResponse" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setAsOrdering(orderId: string, options: any = {}): FetchArgs {
            // verify required parameter 'orderId' is not null or undefined
            if (orderId === null || orderId === undefined) {
                throw new RequiredError('orderId','Required parameter orderId was null or undefined when calling setAsOrdering.');
            }
            const localVarPath = `/api/orders/{orderId}/set_as_ordering`
                .replace(`{${"orderId"}}`, encodeURIComponent(String(orderId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setAsRejected(orderId: string, options: any = {}): FetchArgs {
            // verify required parameter 'orderId' is not null or undefined
            if (orderId === null || orderId === undefined) {
                throw new RequiredError('orderId','Required parameter orderId was null or undefined when calling setAsRejected.');
            }
            const localVarPath = `/api/orders/{orderId}/set_as_rejected`
                .replace(`{${"orderId"}}`, encodeURIComponent(String(orderId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setBackAsOrdered(orderId: string, options: any = {}): FetchArgs {
            // verify required parameter 'orderId' is not null or undefined
            if (orderId === null || orderId === undefined) {
                throw new RequiredError('orderId','Required parameter orderId was null or undefined when calling setBackAsOrdered.');
            }
            const localVarPath = `/api/orders/{orderId}/set_back_as_ordered`
                .replace(`{${"orderId"}}`, encodeURIComponent(String(orderId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        show(orderId: string, options: any = {}): FetchArgs {
            // verify required parameter 'orderId' is not null or undefined
            if (orderId === null || orderId === undefined) {
                throw new RequiredError('orderId','Required parameter orderId was null or undefined when calling show.');
            }
            const localVarPath = `/api/orders/{orderId}/show.json`
                .replace(`{${"orderId"}}`, encodeURIComponent(String(orderId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        todayOrders(options: any = {}): FetchArgs {
            const localVarPath = `/api/orders/today.json`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {OrderUpdateRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update(body: OrderUpdateRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling update.');
            }
            const localVarPath = `/api/orders/update`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"OrderUpdateRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrderControllerApi - functional programming interface
 * @export
 */
export const OrderControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _delete(orderId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<string> {
            const localVarFetchArgs = OrderControllerApiFetchParamCreator(configuration)._delete(orderId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allOrders(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AllOrdersResponse> {
            const localVarFetchArgs = OrderControllerApiFetchParamCreator(configuration).allOrders(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CreateOrderInitialData> {
            const localVarFetchArgs = OrderControllerApiFetchParamCreator(configuration).create(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        edit(orderId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<EditOrderInitialData> {
            const localVarFetchArgs = OrderControllerApiFetchParamCreator(configuration).edit(orderId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        handleSse(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<SseEmitter> {
            const localVarFetchArgs = OrderControllerApiFetchParamCreator(configuration).handleSse(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderViewJson(orderId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<OrderViewInitialData> {
            const localVarFetchArgs = OrderControllerApiFetchParamCreator(configuration).orderViewJson(orderId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {OrderSaveRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        save(body: OrderSaveRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<string> {
            const localVarFetchArgs = OrderControllerApiFetchParamCreator(configuration).save(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setAsCreated(orderId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<string> {
            const localVarFetchArgs = OrderControllerApiFetchParamCreator(configuration).setAsCreated(orderId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setAsDelivered(orderId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<string> {
            const localVarFetchArgs = OrderControllerApiFetchParamCreator(configuration).setAsDelivered(orderId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {SetAsOrderedResponse} body 
         * @param {string} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setAsOrdered(body: SetAsOrderedResponse, orderId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<string> {
            const localVarFetchArgs = OrderControllerApiFetchParamCreator(configuration).setAsOrdered(body, orderId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setAsOrdering(orderId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<string> {
            const localVarFetchArgs = OrderControllerApiFetchParamCreator(configuration).setAsOrdering(orderId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setAsRejected(orderId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<string> {
            const localVarFetchArgs = OrderControllerApiFetchParamCreator(configuration).setAsRejected(orderId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setBackAsOrdered(orderId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<string> {
            const localVarFetchArgs = OrderControllerApiFetchParamCreator(configuration).setBackAsOrdered(orderId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        show(orderId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ShowOrderResponse> {
            const localVarFetchArgs = OrderControllerApiFetchParamCreator(configuration).show(orderId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        todayOrders(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<TodayOrdersResponse> {
            const localVarFetchArgs = OrderControllerApiFetchParamCreator(configuration).todayOrders(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {OrderUpdateRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update(body: OrderUpdateRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<string> {
            const localVarFetchArgs = OrderControllerApiFetchParamCreator(configuration).update(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * OrderControllerApi - factory interface
 * @export
 */
export const OrderControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {string} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _delete(orderId: string, options?: any) {
            return OrderControllerApiFp(configuration)._delete(orderId, options)(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allOrders(options?: any) {
            return OrderControllerApiFp(configuration).allOrders(options)(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create(options?: any) {
            return OrderControllerApiFp(configuration).create(options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        edit(orderId: string, options?: any) {
            return OrderControllerApiFp(configuration).edit(orderId, options)(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        handleSse(options?: any) {
            return OrderControllerApiFp(configuration).handleSse(options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderViewJson(orderId: string, options?: any) {
            return OrderControllerApiFp(configuration).orderViewJson(orderId, options)(fetch, basePath);
        },
        /**
         * 
         * @param {OrderSaveRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        save(body: OrderSaveRequest, options?: any) {
            return OrderControllerApiFp(configuration).save(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setAsCreated(orderId: string, options?: any) {
            return OrderControllerApiFp(configuration).setAsCreated(orderId, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setAsDelivered(orderId: string, options?: any) {
            return OrderControllerApiFp(configuration).setAsDelivered(orderId, options)(fetch, basePath);
        },
        /**
         * 
         * @param {SetAsOrderedResponse} body 
         * @param {string} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setAsOrdered(body: SetAsOrderedResponse, orderId: string, options?: any) {
            return OrderControllerApiFp(configuration).setAsOrdered(body, orderId, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setAsOrdering(orderId: string, options?: any) {
            return OrderControllerApiFp(configuration).setAsOrdering(orderId, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setAsRejected(orderId: string, options?: any) {
            return OrderControllerApiFp(configuration).setAsRejected(orderId, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setBackAsOrdered(orderId: string, options?: any) {
            return OrderControllerApiFp(configuration).setBackAsOrdered(orderId, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        show(orderId: string, options?: any) {
            return OrderControllerApiFp(configuration).show(orderId, options)(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        todayOrders(options?: any) {
            return OrderControllerApiFp(configuration).todayOrders(options)(fetch, basePath);
        },
        /**
         * 
         * @param {OrderUpdateRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update(body: OrderUpdateRequest, options?: any) {
            return OrderControllerApiFp(configuration).update(body, options)(fetch, basePath);
        },
    };
};

/**
 * OrderControllerApi - object-oriented interface
 * @export
 * @class OrderControllerApi
 * @extends {BaseAPI}
 */
export class OrderControllerApi extends BaseAPI {
    /**
     * 
     * @param {string} orderId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderControllerApi
     */
    public _delete(orderId: string, options?: any) {
        return OrderControllerApiFp(this.configuration)._delete(orderId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderControllerApi
     */
    public allOrders(options?: any) {
        return OrderControllerApiFp(this.configuration).allOrders(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderControllerApi
     */
    public create(options?: any) {
        return OrderControllerApiFp(this.configuration).create(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} orderId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderControllerApi
     */
    public edit(orderId: string, options?: any) {
        return OrderControllerApiFp(this.configuration).edit(orderId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderControllerApi
     */
    public handleSse(options?: any) {
        return OrderControllerApiFp(this.configuration).handleSse(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} orderId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderControllerApi
     */
    public orderViewJson(orderId: string, options?: any) {
        return OrderControllerApiFp(this.configuration).orderViewJson(orderId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {OrderSaveRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderControllerApi
     */
    public save(body: OrderSaveRequest, options?: any) {
        return OrderControllerApiFp(this.configuration).save(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} orderId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderControllerApi
     */
    public setAsCreated(orderId: string, options?: any) {
        return OrderControllerApiFp(this.configuration).setAsCreated(orderId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} orderId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderControllerApi
     */
    public setAsDelivered(orderId: string, options?: any) {
        return OrderControllerApiFp(this.configuration).setAsDelivered(orderId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {SetAsOrderedResponse} body 
     * @param {string} orderId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderControllerApi
     */
    public setAsOrdered(body: SetAsOrderedResponse, orderId: string, options?: any) {
        return OrderControllerApiFp(this.configuration).setAsOrdered(body, orderId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} orderId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderControllerApi
     */
    public setAsOrdering(orderId: string, options?: any) {
        return OrderControllerApiFp(this.configuration).setAsOrdering(orderId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} orderId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderControllerApi
     */
    public setAsRejected(orderId: string, options?: any) {
        return OrderControllerApiFp(this.configuration).setAsRejected(orderId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} orderId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderControllerApi
     */
    public setBackAsOrdered(orderId: string, options?: any) {
        return OrderControllerApiFp(this.configuration).setBackAsOrdered(orderId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} orderId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderControllerApi
     */
    public show(orderId: string, options?: any) {
        return OrderControllerApiFp(this.configuration).show(orderId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderControllerApi
     */
    public todayOrders(options?: any) {
        return OrderControllerApiFp(this.configuration).todayOrders(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {OrderUpdateRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderControllerApi
     */
    public update(body: OrderUpdateRequest, options?: any) {
        return OrderControllerApiFp(this.configuration).update(body, options)(this.fetch, this.basePath);
    }

}
/**
 * OrderEntryControllerApi - fetch parameter creator
 * @export
 */
export const OrderEntryControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} orderEntryId 
         * @param {string} dishEntryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        delete1(orderEntryId: string, dishEntryId: string, options: any = {}): FetchArgs {
            // verify required parameter 'orderEntryId' is not null or undefined
            if (orderEntryId === null || orderEntryId === undefined) {
                throw new RequiredError('orderEntryId','Required parameter orderEntryId was null or undefined when calling delete1.');
            }
            // verify required parameter 'dishEntryId' is not null or undefined
            if (dishEntryId === null || dishEntryId === undefined) {
                throw new RequiredError('dishEntryId','Required parameter dishEntryId was null or undefined when calling delete1.');
            }
            const localVarPath = `/api/order_entries/{orderEntryId}/dish_entry/{dishEntryId}/delete`
                .replace(`{${"orderEntryId"}}`, encodeURIComponent(String(orderEntryId)))
                .replace(`{${"dishEntryId"}}`, encodeURIComponent(String(dishEntryId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} orderEntryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        revertToUnpaid(orderEntryId: string, options: any = {}): FetchArgs {
            // verify required parameter 'orderEntryId' is not null or undefined
            if (orderEntryId === null || orderEntryId === undefined) {
                throw new RequiredError('orderEntryId','Required parameter orderEntryId was null or undefined when calling revertToUnpaid.');
            }
            const localVarPath = `/api/order_entries/{orderEntryId}/revert_to_unpaid`
                .replace(`{${"orderEntryId"}}`, encodeURIComponent(String(orderEntryId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {OrderEntrySaveRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        save1(body: OrderEntrySaveRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling save1.');
            }
            const localVarPath = `/api/order_entries/save`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"OrderEntrySaveRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} orderEntryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setAsConfirmedAsPaid(orderEntryId: string, options: any = {}): FetchArgs {
            // verify required parameter 'orderEntryId' is not null or undefined
            if (orderEntryId === null || orderEntryId === undefined) {
                throw new RequiredError('orderEntryId','Required parameter orderEntryId was null or undefined when calling setAsConfirmedAsPaid.');
            }
            const localVarPath = `/api/order_entries/{orderEntryId}/confirm_as_paid`
                .replace(`{${"orderEntryId"}}`, encodeURIComponent(String(orderEntryId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {OrderEntryUpdateRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update1(body: OrderEntryUpdateRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling update1.');
            }
            const localVarPath = `/api/order_entries/update`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"OrderEntryUpdateRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrderEntryControllerApi - functional programming interface
 * @export
 */
export const OrderEntryControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} orderEntryId 
         * @param {string} dishEntryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        delete1(orderEntryId: string, dishEntryId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<string> {
            const localVarFetchArgs = OrderEntryControllerApiFetchParamCreator(configuration).delete1(orderEntryId, dishEntryId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} orderEntryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        revertToUnpaid(orderEntryId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<string> {
            const localVarFetchArgs = OrderEntryControllerApiFetchParamCreator(configuration).revertToUnpaid(orderEntryId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {OrderEntrySaveRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        save1(body: OrderEntrySaveRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<string> {
            const localVarFetchArgs = OrderEntryControllerApiFetchParamCreator(configuration).save1(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} orderEntryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setAsConfirmedAsPaid(orderEntryId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<string> {
            const localVarFetchArgs = OrderEntryControllerApiFetchParamCreator(configuration).setAsConfirmedAsPaid(orderEntryId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {OrderEntryUpdateRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update1(body: OrderEntryUpdateRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<string> {
            const localVarFetchArgs = OrderEntryControllerApiFetchParamCreator(configuration).update1(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * OrderEntryControllerApi - factory interface
 * @export
 */
export const OrderEntryControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {string} orderEntryId 
         * @param {string} dishEntryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        delete1(orderEntryId: string, dishEntryId: string, options?: any) {
            return OrderEntryControllerApiFp(configuration).delete1(orderEntryId, dishEntryId, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} orderEntryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        revertToUnpaid(orderEntryId: string, options?: any) {
            return OrderEntryControllerApiFp(configuration).revertToUnpaid(orderEntryId, options)(fetch, basePath);
        },
        /**
         * 
         * @param {OrderEntrySaveRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        save1(body: OrderEntrySaveRequest, options?: any) {
            return OrderEntryControllerApiFp(configuration).save1(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} orderEntryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setAsConfirmedAsPaid(orderEntryId: string, options?: any) {
            return OrderEntryControllerApiFp(configuration).setAsConfirmedAsPaid(orderEntryId, options)(fetch, basePath);
        },
        /**
         * 
         * @param {OrderEntryUpdateRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update1(body: OrderEntryUpdateRequest, options?: any) {
            return OrderEntryControllerApiFp(configuration).update1(body, options)(fetch, basePath);
        },
    };
};

/**
 * OrderEntryControllerApi - object-oriented interface
 * @export
 * @class OrderEntryControllerApi
 * @extends {BaseAPI}
 */
export class OrderEntryControllerApi extends BaseAPI {
    /**
     * 
     * @param {string} orderEntryId 
     * @param {string} dishEntryId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderEntryControllerApi
     */
    public delete1(orderEntryId: string, dishEntryId: string, options?: any) {
        return OrderEntryControllerApiFp(this.configuration).delete1(orderEntryId, dishEntryId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} orderEntryId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderEntryControllerApi
     */
    public revertToUnpaid(orderEntryId: string, options?: any) {
        return OrderEntryControllerApiFp(this.configuration).revertToUnpaid(orderEntryId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {OrderEntrySaveRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderEntryControllerApi
     */
    public save1(body: OrderEntrySaveRequest, options?: any) {
        return OrderEntryControllerApiFp(this.configuration).save1(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} orderEntryId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderEntryControllerApi
     */
    public setAsConfirmedAsPaid(orderEntryId: string, options?: any) {
        return OrderEntryControllerApiFp(this.configuration).setAsConfirmedAsPaid(orderEntryId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {OrderEntryUpdateRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderEntryControllerApi
     */
    public update1(body: OrderEntryUpdateRequest, options?: any) {
        return OrderEntryControllerApiFp(this.configuration).update1(body, options)(this.fetch, this.basePath);
    }

}
/**
 * RestaurantControllerApi - fetch parameter creator
 * @export
 */
export const RestaurantControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} restaurantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRestaurant(restaurantId: string, options: any = {}): FetchArgs {
            // verify required parameter 'restaurantId' is not null or undefined
            if (restaurantId === null || restaurantId === undefined) {
                throw new RequiredError('restaurantId','Required parameter restaurantId was null or undefined when calling deleteRestaurant.');
            }
            const localVarPath = `/api/restaurants/{restaurantId}/delete`
                .replace(`{${"restaurantId"}}`, encodeURIComponent(String(restaurantId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} restaurantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editRestaurant(restaurantId: string, options: any = {}): FetchArgs {
            // verify required parameter 'restaurantId' is not null or undefined
            if (restaurantId === null || restaurantId === undefined) {
                throw new RequiredError('restaurantId','Required parameter restaurantId was null or undefined when calling editRestaurant.');
            }
            const localVarPath = `/api/restaurants/{restaurantId}/edit.json`
                .replace(`{${"restaurantId"}}`, encodeURIComponent(String(restaurantId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        indexRestaurants(options: any = {}): FetchArgs {
            const localVarPath = `/api/restaurants.json`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RestaurantSaveRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveRestaurant(body: RestaurantSaveRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling saveRestaurant.');
            }
            const localVarPath = `/api/restaurants/save`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"RestaurantSaveRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} restaurantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        showRestaurant(restaurantId: string, options: any = {}): FetchArgs {
            // verify required parameter 'restaurantId' is not null or undefined
            if (restaurantId === null || restaurantId === undefined) {
                throw new RequiredError('restaurantId','Required parameter restaurantId was null or undefined when calling showRestaurant.');
            }
            const localVarPath = `/api/restaurants/{restaurantId}/show.json`
                .replace(`{${"restaurantId"}}`, encodeURIComponent(String(restaurantId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RestaurantUpdateRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRestaurant(body: RestaurantUpdateRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateRestaurant.');
            }
            const localVarPath = `/api/restaurants/update`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"RestaurantUpdateRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RestaurantControllerApi - functional programming interface
 * @export
 */
export const RestaurantControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} restaurantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRestaurant(restaurantId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<string> {
            const localVarFetchArgs = RestaurantControllerApiFetchParamCreator(configuration).deleteRestaurant(restaurantId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} restaurantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editRestaurant(restaurantId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<EditRestaurantResponse> {
            const localVarFetchArgs = RestaurantControllerApiFetchParamCreator(configuration).editRestaurant(restaurantId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        indexRestaurants(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<IndexResponse> {
            const localVarFetchArgs = RestaurantControllerApiFetchParamCreator(configuration).indexRestaurants(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {RestaurantSaveRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveRestaurant(body: RestaurantSaveRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<string> {
            const localVarFetchArgs = RestaurantControllerApiFetchParamCreator(configuration).saveRestaurant(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} restaurantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        showRestaurant(restaurantId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ShowRestaurantResponse> {
            const localVarFetchArgs = RestaurantControllerApiFetchParamCreator(configuration).showRestaurant(restaurantId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {RestaurantUpdateRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRestaurant(body: RestaurantUpdateRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<string> {
            const localVarFetchArgs = RestaurantControllerApiFetchParamCreator(configuration).updateRestaurant(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * RestaurantControllerApi - factory interface
 * @export
 */
export const RestaurantControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {string} restaurantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRestaurant(restaurantId: string, options?: any) {
            return RestaurantControllerApiFp(configuration).deleteRestaurant(restaurantId, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} restaurantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editRestaurant(restaurantId: string, options?: any) {
            return RestaurantControllerApiFp(configuration).editRestaurant(restaurantId, options)(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        indexRestaurants(options?: any) {
            return RestaurantControllerApiFp(configuration).indexRestaurants(options)(fetch, basePath);
        },
        /**
         * 
         * @param {RestaurantSaveRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveRestaurant(body: RestaurantSaveRequest, options?: any) {
            return RestaurantControllerApiFp(configuration).saveRestaurant(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} restaurantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        showRestaurant(restaurantId: string, options?: any) {
            return RestaurantControllerApiFp(configuration).showRestaurant(restaurantId, options)(fetch, basePath);
        },
        /**
         * 
         * @param {RestaurantUpdateRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRestaurant(body: RestaurantUpdateRequest, options?: any) {
            return RestaurantControllerApiFp(configuration).updateRestaurant(body, options)(fetch, basePath);
        },
    };
};

/**
 * RestaurantControllerApi - object-oriented interface
 * @export
 * @class RestaurantControllerApi
 * @extends {BaseAPI}
 */
export class RestaurantControllerApi extends BaseAPI {
    /**
     * 
     * @param {string} restaurantId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RestaurantControllerApi
     */
    public deleteRestaurant(restaurantId: string, options?: any) {
        return RestaurantControllerApiFp(this.configuration).deleteRestaurant(restaurantId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} restaurantId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RestaurantControllerApi
     */
    public editRestaurant(restaurantId: string, options?: any) {
        return RestaurantControllerApiFp(this.configuration).editRestaurant(restaurantId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RestaurantControllerApi
     */
    public indexRestaurants(options?: any) {
        return RestaurantControllerApiFp(this.configuration).indexRestaurants(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {RestaurantSaveRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RestaurantControllerApi
     */
    public saveRestaurant(body: RestaurantSaveRequest, options?: any) {
        return RestaurantControllerApiFp(this.configuration).saveRestaurant(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} restaurantId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RestaurantControllerApi
     */
    public showRestaurant(restaurantId: string, options?: any) {
        return RestaurantControllerApiFp(this.configuration).showRestaurant(restaurantId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {RestaurantUpdateRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RestaurantControllerApi
     */
    public updateRestaurant(body: RestaurantUpdateRequest, options?: any) {
        return RestaurantControllerApiFp(this.configuration).updateRestaurant(body, options)(this.fetch, this.basePath);
    }

}
