
import Price from "../../../commons/PriceElement.vue";
import CreateOrderEntry from "./CreateOrderEntry.vue";
import EditOrderEntry from "./EditOrderEntry.vue";
import ShowOrderEntry from "./ShowOrderEntry.vue";
import TitleWithPaymentStatus from "@/views/orders/components/TitleWithPaymentStatus.vue";
import Vue from "vue";
import {Prop} from "vue-property-decorator";
import Component from "vue-class-component";
import {ParticipantsOrderEntry, ShowOrderDto} from "../../../../frontend-client";
import OrderStateEnum = ShowOrderDto.OrderStateEnum;

@Component({
  components: {
    TitleWithPaymentStatus,
    Price,
    CreateOrderEntry,
    EditOrderEntry,
    ShowOrderEntry
  }
})
export default class OrderEntriesCard extends Vue {
  @Prop() order!: ShowOrderDto;
  @Prop() orderEntry!: ParticipantsOrderEntry;
  @Prop() entryId!: string;
  @Prop() currentUserId!: string;

  isOrderEntryOwner() {
    return this.orderEntry.userId === this.currentUserId;
  }

  createEntry() {
    this.$store.commit(`modifyOrderEntry/setDishEntryCreating`, {});
  }

  get isEntryCreating() {
    return this.$store.state.modifyOrderEntry.isEntryCreating;
  }

  get isEntryEdited() {
    return this.$store.state.modifyOrderEntry.isEntryEdited;
  }

  get dishEntryId() {
    return this.$store.state.modifyOrderEntry.dishEntryId;
  }

  canAddNewEntry() {
    return this.order.orderState === OrderStateEnum.CREATED && this.isOrderEntryOwner() && !this.isEntryEdited
  }
}
