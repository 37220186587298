
import router from "../../../router/index";
import {Prop} from "vue-property-decorator";
import Component from "vue-class-component";
import Vue from "vue";
import ErrorHandler from "@/lib/ErrorHandler";
import OrdersApiConnector from "@/lib/api/OrdersApiConnector";

@Component({})
export default class OrderLockedWarning extends Vue {
  @Prop() orderId!: string;

  ordersConnector = new OrdersApiConnector()

  placeOrder() {
    router.push({name: "OrderView", params: {id: this.orderId}});
  }

  unlockOrder() {
    this.ordersConnector
        .setOrderAsCreated(this.orderId)
        .then(() => {
          this.$store.commit("setLoadingTrue");
          this.$store.dispatch(`showOrder/fetchOrderDataAction`, this.$store.state.showOrder.order.id);
        })
        .catch(errResponse => ErrorHandler.handleError(errResponse));
  }
}
